body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fullOpacity {
  opacity: 1 !important;
}

.zoom-in-button {
  cursor: pointer;
  animation: zoomIn 0.5s ease forwards;
  opacity: 0; /* Initially hidden */
}

@keyframes zoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0; /* Start with opacity 0 */
  }
  100% {
    transform: scale(1);
    opacity: 1; /* End with opacity 1 */
  }
}